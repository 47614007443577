@import url('https://fonts.googleapis.com/css2?family=Dhurjati&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;600&display=swap');

.home {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.robot-condesed {
    font-family: 'Roboto Condensed', sans-serif !important ;
    font-weight: 400;


}

.robot-condesed-title {
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 600 !important;
    
}

.section-one {
    height: 85vh;
    background-color: transparent !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    color: white;
    overflow: hidden !important;
}

.section-one p,
.section-one a {
    position: relative;
    z-index: 3;
}

.hero_content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.hero-title {
    font-size: 80px;
    margin-bottom: 0;
    width: 100%;
}

.hero-subtitle {
    font-size: 21px;
    width: 100%;
}

.play-btn {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    border: 2px solid white;
    padding-block: .4rem;
    padding-inline: 1.5rem;
    cursor: pointer;
}

.play-btn svg {
    width: 18px;
    height: 18px;
    fill: white;
    margin-right: 1rem;
}

.play-btn p {
    margin-right: 1rem;
}

.hero_video {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: hidden;
    ;
}

h2>svg {
    margin-right: .5rem;

}

.hero_video iframe {
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 300%;
    /*   clip-path: inset(130px 130px);
    transform: scale(1.5); */

}
.hero_video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1140px) {
    .hero_video iframe {
        position: absolute;
        width: 150%;
        left: -25%;
        max-width: none;
    }
}

.home_packages {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.home_packages>div img {
    width: 360px;
    height: auto;

}

.section-two {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.section-three {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


}

.section-three-container {
    width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.s_th_txt {
    flex: 1;

}

.s_th_imges {
    flex: 1;

}

.s_th_imges>div {
    position: relative;

}

.s_th_imges img {
    position: relative;
    z-index: 1;
    width: 250px;
    height: 300px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;


}

.sm-sc-fix {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
    border: 0;
}

.s_th_imges img:first-child {
    transform: translate(-95px, -33px);
    z-index: 2;
    box-shadow: 1px 1px 14px 11px #2d2c2c4f;

}

@media (max-width: 1435px) {
    .home_packages {
        flex-direction: column;
        width: fit-content;
    }


    .section-one {

        justify-content: flex-start;
        overflow: hidden !important;
    }

    .hero_content {
        margin-top: 9vh !important;
    }

    .section-three-container {
        flex-direction: column-reverse;
        width: fit-content !important;
        height: max-content;
    }
    .s_th_imges {
        flex: unset !important;
     }

    .section-four .container {
        flex-direction: column;
    }

    .section-four {
        padding: 0px !important;
    }



    .section-four .image-container {
        transform: translate(50px, 100px) !important;
    }

    .section-four p {
        width: 100% !important;
    }

    .sec-four-btn {
        font-size: 24px !important;
        margin-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .section-four .image-container {
        transform: scale(.7) translate(43px, 67px) !important;
    }

    .section-four .container {
        width: 90% !important;
    }

    .section-four .txt-container {
        margin-top: -125px !important;
    }
}

.cursor {
    cursor: pointer !important;
    transition: all .2s ease-in-out;
}

.cursor:hover {
    transform: scale(1.05) translateY(-10px);
    color: rgb(46, 46, 46) !important;
    border-radius: 8px !important;
}


@media (max-width: 800px) {
    .title-m-top {
        margin-top: -25px !important;
    }

    .sm-sc-fix {
        font-size: 21px !important;
        margin-inline: 1rem !important;
        font-weight: bolder !important;
        font-family: 'Roboto', sans-serif;
        padding: 15px !important;
        line-height: 100% !important;
    }

    .special-title {
        font-size: 10vw !important;
        margin-top: 4vw !important;
        width: 100%;
        margin-bottom: 0 !important;
        text-align: center;

    }

    .arrow-list li {
        font-size: 15px !important;
        font-weight: bolder !important;
        position: relative;
    }

    .arrow-list {}

    h2>svg {


        height: auto;
    }
}

.bg-img-p {
    background: url('https://imagedelivery.net/pPmcWI57Uxy-_rb5sInlGg/1c4d74c5-6b26-40f7-e0ed-689dc2a6ca00/public');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
    padding: 5px;
    color: white !important;
}

.arrow-list {
    max-width: 550px;
    text-align: left !important;
    font-size: 24px;
    line-height: 60px;
    font-family: 'Roboto Condensed', sans-serif;
    overflow: show !important;
    list-style-position: outside !important;


}

.arrow-list li {
    overflow: show !important;
    position: relative;

}

.arrow-list li::before {
    content: "";
    display: inline-block;
    width: 30px;
    /* Adjust the size as needed */
    height: 30px;
    /* Adjust the size as needed */
    background-image: url('https://trainwithmo.com/Layer_1.png');
    /* Replace 'your-svg-image.svg' with your SVG file path */
    background-size: cover !important;
    background-position: center center !important;
    margin-right: 20px;

    position: absolute;
    left: -35px;
    /* Add space between the bullet and text */
}

@media (max-width: 768px) {
    .arrow-list li {
        overflow: show !important;
        left: -20px;
        margin-right: 8px !important;
        line-height: 18px !important;
    }

    .arrow-list li::before {
        content: "";
        display: inline-block;
        width: 15px;
        /* Adjust the size as needed */
        height: 15px;
        /* Adjust the size as needed */
        background-image: url('https://trainwithmo.com/Layer_1.png');
        /* Replace 'your-svg-image.svg' with your SVG file path */
        background-size: cover !important;
        background-position: center center !important;
        margin-right: 5px;
        left: -17px;
        top: 7px;

        /* Add space between the bullet and text */
    }
}

.big-btn {
    color: #000;
    text-align: center;
    font-family: Roboto Condensed;
    font-size: 49px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    background: linear-gradient(90deg, #FDDA05 0.03%, #F14B1C 100.31%) !important;
    padding: 15px;
    border: 0px !important;
}

.gradiant-text {
    background: linear-gradient(270deg, #FDDA05 1.16%, #F14B1C 99.73%);
    background-clip: text;
    font-weight: 600 !important;
    font-family: 'Roboto Condensed', sans-serif !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-four {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    padding: 4rem;
    padding-bottom: 0 !important;
}

.section-four .container {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.section-four .image-container {
    flex: 1;
    display: flex !important;
    flex-direction: column;
    width: 350px;
    transform: translateY(54px) translateX(40px);
}


.section-four .image-container img:first-child {
    transform: translate(81px, -11px);
}

.section-four .image-container img:nth-child(2) {
    transform: translate(-93px, -185px);
}

.section-four .txt-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    align-items: center;
    justify-content: center;
}

.section-four .txt-container h2 {
    color: #000;
    text-align: center;
    margin-top: 0 !important;
    font-size: 60px;
    font-style: normal;
    margin-bottom: 0 !important;
    font-weight: 700;
    line-height: 100%;
    max-width: 455px;
}

.section-four p {
    max-width: 455px;
    color: #000;

    font-family: Roboto Condensed;
    font-size: 15px;
    line-height: 26px;
    text-align: left;
}

.section-four button {
    max-width: 455px !important;

    padding-inline: 10px !important;
    margin-bottom: 100px;
}

.section-four img {
    width: 300px;
    height: auto;
    object-fit: cover;
}

.sec-four-btn {
    width: 100% !important;
    border: none;
}

.normal-font {
    font-family: 'Montserrat', sans-serif;
}
.qoute {
    position: relative;
}
.qoute::before {
    content: "“";
    position: absolute;
    left: -30px;
}
.qoute::after {
    content: "”";
    position: absolute;
    right: -30px;
}
.br {
    display: none;
}
.small-italic {
    font-family: sans-serif;
    font-style: italic;
    font-size: 14px;
    display: block;
    font-weight: 200 !important;
    line-height: 20px;
    padding-left: 0px;
    margin-top: 0px;
    color: #232323 !important;
    font-weight: lighter !important;
}

@media (max-width: 500px) {
    .small-italic {
        font-size: 10px;
        padding-left: 0px;
        line-height: 100%;
    }

    .hero-title {
        font-size: 40px !important;
    }

    .img-fix-sm-sc {
        transform: scale(.8) translateX(44px)
    }

    .s_th_txt {
        margin-top: -5rem;
    }
}

@media (max-width: 390px) {
    .margin-fix {
        margin-top: 0rem !important;
    }
}
@media (max-width: 728px) {
    .title-sm-sc{
        font-size:33px !important;
        line-height: 100% !important;
        margin-bottom: -40px !important;
    }
    .banner-txt svg {
        width: 15px;
        height: 15px;
    }
    .br {
        display: block;
    }
    .qoute {
        font-size: 25px !important;
    }
   
    
}
@media (max-width: 1230px) {
    .banner-txt  {
        font-size: 15px !important;
    }
    .banner-txt svg {
        width: 15px;
        height: 15px;
    }
}