.app-header {
   position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.header_container {
    width: 80%;
    display: flex;
    display: flex;
    align-items: center;
    justify-content: center;

}
.header_container  > div {
    display: flex;
    flex-direction: column;
}
.header_container  > div > a {
    display: block;
    margin-top: 15px;
} 
.header_container  > div > a:last-child {
    margin-bottom: 15px;
}

.header-img-logo {
    width: 60px;
    height: auto;
}

.header_container a {
    color: white;
    text-decoration: none;
    font-size: large;
    text-transform: uppercase;
    display: block;
    position: relative;
    width: fit-content;
    cursor: pointer;

}
.header_container a::before {
    content: "";
    position: absolute;
    height: 2px;
    background-color: white;
    left: 0;
    top: 100%;
    width: 0%;
    transition: all .2s;

}

.header_container a:hover:before {
    width: 100%;
}


.page-list {
    display: flex;
}

.sticky-header {
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 401;
    box-shadow: 0 1px 8px -1px rgba(23,23,23,0.2);

}
.sticky-header img {
    filter: invert();
}