.tabs {
    width: 100%;
    display: flex;

}

.tabs button {
    background-color: transparent !important;
    outline: none;
    border: none;
    flex: 1;
    font-size: 1.8rem;
    margin-block: 20px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-out;
    box-shadow: none !important;


}
.tabs button:hover {
    filter: none;
}
.tabs button::before {
    content: "";
    position: absolute;
    top: 100%;
    width: 0%;
    height: 2px;
    background-color: aqua;
    transition: all 0.2s ease-out;

}
.tabs .active {
    color: aqua;
}
.tabs .active::before {
    width: 80%;
}