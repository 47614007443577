.footer {
    font-family: 'Roboto Condensed', sans-serif !important;
}
.footer ul {
    list-style: none !important;
    /* margin-inline: 12vw; */
    width: 50%;
   
    display: flex ;
    align-items: center ;
    justify-content: center ;
    flex-direction: column ;
    padding: 0!important;
   
}
.links-footer > ul:first-child {
    flex-direction: row;
    justify-content: space-around;
    align-items: center ;
    max-width: 500px;
}
.links-footer > ul:nth-child(3) {
    max-width: 500px;
    margin-block: 2rem;
}
.footer a {
    width: 50%;
    color: white;
    text-decoration: none ;
    
}
.links-container {
    width: 80%;
    background: black;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;

}
.footer ul  li { 
    text-align: left !important;
    max-width:300px;
    margin-block: 5px;
    letter-spacing: 2px   ;
    display: flex !important;
    font-size: 15px !important;
    justify-content: flex-start;
    height: 40px;
    align-items: center ;
    width: 100%;
    min-width: 300px;
}
.footer ul  li  p {
    margin-top: 0;
    letter-spacing: 1px;
  
    height: 20px;
    line-height:30px !important;
}
.footer ul  li  svg, .footer ul  li  img {
 
    margin-right: 1.5rem;
}

.footer h2 {
    font-weight: normal !important;
    margin-block: 0px;
    font-size: 15px;
}

.footer-contact {
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important

    
}
.terms {
    width: 80%;
    background: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 3rem;
    margin-top: 0rem;
}
input:focus:required + .label > label, input:not(:placeholder-shown) + .label > label{
    /* Define your label styles when the input is focused or valid */
    opacity: 0; /* For example, change the label text color to red */
}
.divider {
    background-color: rgba(131, 131, 131, 0.363);
    height: 1px;
    width: 80% ;
    align-self: center;
    margin-bottom: .5rem;
    display: none;
  }
@media (max-width: 1300px) {

   .links-footer {
    flex-direction: column;
    width: 100% !important;
   
   }
   .divider {
    display: block;
   }
   .terms{
    margin-top: 8rem;
   }
   .links-footer ul {
    width: 80% ;
    align-items: flex-start;
   }
  

}
.branding-log {
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 100px;

}
@media (max-width: 728px) {
    .footer a {
        width: 80%;
    }
    .footer ul {
        max-width: 90% !important;
    }
    .terms{
        margin-bottom: 5rem;
    }
    .branding-log {
    
        right: 50%;
        transform: translateX(50%);
        width: unset !important;


        
    
    }
    
}


@media (max-width: 480px) {
    .links-footer > ul:nth-child(2) {
        padding-left: 30px !important;
    }
}

