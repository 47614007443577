@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden !important;
  font-family: 'Roboto Condensed', sans-serif !important ;
  font-weight: 400;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  scroll-behavior: smooth;
}

/* .App section {
  width: 100%;
  background-color: white;
  margin-bottom: 0 !important
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page-editor {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0;
  z-index: 402;
  background-color: white;
  width: 300px;
  box-shadow: -5px 1px 20px 1px rgba(0, 0, 0, 0.3);
  padding-bottom: 2rem;
  padding: 1rem;

  overflow-y: auto;
  padding-bottom: 50px;
  transition: all .3s ease-out;

}

.page-editor h1 {
  font-weight: lighter;
  font-size: 24px;
}

.page-editor p {
  text-align: left;
  margin-bottom: 0;
  font-weight: bold;

}

.page-editor input {
  width: 85%;
  padding: 0.8rem;
  margin: .2rem;
  margin-left: 1rem;
  border-color: rgba(128, 128, 128, 0.116);

}

.add-package-input {
  width: 80%;
  padding: 0.8rem;
  margin: .2rem;
  border-color: rgba(128, 128, 128, 0.116);
  margin-left: 1rem;
}

.editor-input {
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  padding: 10px;
  margin-inline: 1rem;
  margin-block: 5px;
  border: 1px solid rgba(128, 128, 128, 0.281);

}

.editor-input p {
  width: 80%;
  margin: 0;
  text-align: left;
}

.editor-input input {
  width: 20%;

}

/* .active-target {
  border: 1px dotted gray !important;
  position: relative;
  z-index: 400 !important;
  box-sizing: border-box;


}

.active-target::before {
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 450 !important;
  height: 100%;
}

.active-selection {
  position: relative;
  z-index: 400 !important;
} */
img {
  cursor: pointer;
}
.skeleton-loader {
  width: 100%;
  height: 100vh;
  /* Adjust the height as needed */

  background: linear-gradient(90deg, #222 25%, #444 50%, #222 75%);
  background-size: 200% 100%;
  animation: loading-skeleton 1.5s infinite;

  /* Add some margin and border radius for style */
  margin: 10px 0;
  border-radius: 5px;
}

@keyframes loading-skeleton {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.blurred-view {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.204);
  z-index: 399;
  backdrop-filter: blur(10px);

}

.footer-icons a {
  padding: .8rem;
  border: 3px solid rgb(9, 2, 41);
  border-radius: 100%;
  margin-inline: .5rem;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.update-all-button {
  padding: 1rem;
  border-color: white;
  font-size: 1.5rem;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 410;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .2s ease-in-out;
}

.update-all-button:hover {
  filter: invert();
}

.modal-small {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  z-index: 1000;
}

button {
  background-color: transparent;
  outline: none;
  background-color: white;
  margin-block: 1rem;
  cursor: pointer;
  padding: 8px;
  box-shadow: 0 1px 8px -1px rgba(23, 23, 23, 0.2);
  border-radius: 5px;
  border-color: rgba(223, 225, 229, 0);
  font-size: 1.3rem;
  font-weight: normal;
  transition: all .3s ease-out;
}

button:hover {
  transform: scale(1.05) translateY(10px);
}
ul {
  list-style: none;
}
.special-input {
  padding: 0.7rem;
  border: none;
  border-bottom: 1px solid black;
  margin-right: 1rem;
}

@media (max-width: 500px) {
  .no-padding-sc {
    padding-left: 0;
  
  }
  .width-sm {
    width: 86% !important;
  }
}

.banner-txt {
  margin: 0px;
  font-size: 14px;
  font-weight: 400;

}
.bar {
  position: fixed;
  bottom: 75px;
  left: 155px;
  z-index: 1000;
  width: 78%;
  border: 1px solid white;
  height: 15px;
  background-color: transparent;
  border-radius: 8px;
}
.bar >div {
  background-color: white;
  border-radius: 8px;
  width: 0%;
  animation: loading 60s linear infinite;
  height: 15px;
}

.fab {
  
  bottom: 40px;
  left: 40px;
  z-index: 1000;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

 
 
}

.video-controls  {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 1000;
 
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (max-width: 768px) {
  .video-controls {
    left: 20px;
  }
}
.video-controls svg {

  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-left: 1.5rem;
  transition: all .2s ease-out;
}
.fab svg {
  z-index: 1001;
  cursor: pointer;
  width: 80px;
  height: 80px;
  opacity: .8;
  transition: all .5s ease-out;
  position: relative;
 
 
 }
.video-controls svg:hover {
  transform: scale(1.5);
 
 }
.fab::before {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  z-index:  1001;
  border: 1px solid white;
  border-radius: 50%;
 
  animation: scale 1s linear infinite;
}

.fab::after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.404);
  border-radius: 50%;
 
  animation: scale 1s linear infinite;
}



.fab:hover::after {
  animation: scale 3s linear infinite;
}
.fab:hover::before {
  animation: scale 3s linear infinite;
}
.fab:hover svg {

 opacity: 1;
 transform: scale(.8);
}

@keyframes scale {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes loading {
  0% {
   width: 0%;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .fab {
    position: fixed;
    bottom: 15px;
    left: 15px;
  }
  .bar {
    display: none;
  }
}


.spinner {
  width: 80px;
  height: 80px;
  opacity: .5 !important;
  animation: spin 2s linear infinite;
}
/* .spinner::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.404);
  border-radius: 50%;
  animation: scale 1s linear infinite;
  animation: unset !important;
}
.spinner::after {
  content: "";
  position: absolute;
  width: 140px;
  height: 140px;
  border: 1px solid rgba(255, 255, 255, 0.404);
  border-radius: 50%;
  animation: unset !important;
} */

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* .dash::before {
  content: "—";
  position: absolute;
  left: -5px;
  top: 65%;
}
.dash::after {
  content: "—";
  position: absolute;
  right: -5px;
  top: 65%;
} */


.flash {
  position: relative;
  display: flex;
  padding: 0px !important;
 
 

}

/* 
.flash::before {
  position: absolute;
  content: "";
  
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  border-radius: 20px;
  animation-delay: .375s;
  background: linear-gradient(180deg, transparent, #fff);
 
}
.flash::after {
  position: absolute;
  content: "";
  
  right: 0px;
  bottom: -5px;
  width: 100%;
  height: 2px;
  border-radius: 20px;
  animation-delay: .375s;
  background: linear-gradient(180deg, transparent, #fff);
  animation: silde 1.5s linear infinite;
 
}

@keyframes silde {
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-700px);
  }
} */
.shining-box {
  transition: all .3s linear;
}
.shining-box:hover {
  filter: drop-shadow(0 0 90px rgba(255, 255, 255, 0.5));
  border: 1px solid white;
}





















.animated-text {
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  animation: expandFromLeft 4s linear;
}

@keyframes expandFromLeft {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}